import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import axios from "axios";

import star1 from "../../images/stars/star_1.png";
import star2 from "../../images/stars/star_2.png";
import star3 from "../../images/stars/star_3.png";
import star4 from "../../images/stars/star_4.png";
import star5 from "../../images/stars/star_5.png";

import { CustomYellowHoverBtn } from "../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";
import { CopySVG } from "../../images/SVG/Copy";

import "./CongratPage.scss";


export function CongratPage() {
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    const userName = sessionStorage.getItem('twitterUsername') || 'null'; // Default username
    axios.get(`https://xfluencersserver-production.up.railway.app/congratulations-page?userName=${encodeURIComponent(userName)}`)
      .then(response => {
        console.log('Email sent:', response.data); // Логуємо відповідь сервера
        // Додаткові дії залежно від відповіді
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    const userName = sessionStorage.getItem('twitterUsername') || 'null';
    axios.get(`https://xfluencersserver-production.up.railway.app/api/get-reff/${userName}`)
      .then(response => {
        const confirmationCode = response.data.confirmationCode;
        const referralCode = confirmationCode.substring(0, 8); // Use the first 8 characters of the confirmation code
        setReferralLink(`https://xfluencers.wtf/?r=${referralCode}`);
      })
      .catch(error => {
        console.error("Failed to fetch user data:", error);
        toast.error("Failed to fetch referral data.");
      });
  }, []);

  const copyToClipboard = (e: React.FormEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(referralLink).then(() => {
      toast.success("Link copied to clipboard!");
    }).catch(() => {
      toast.error("Failed to copy the link.");
    });
  };

  return (
    <div className="congrat-section">
      <div className="container">
        <div className="congrat-section_container">
          <div className="stars">
            <img src={star1} alt="Star 1" className="star star1" />
            <img src={star2} alt="Star 2" className="star star2" />
            <img src={star3} alt="Star 3" className="star star3" />
            <img src={star4} alt="Star 4" className="star star4" />
            <img src={star5} alt="Star 5" className="star star5" />
          </div>
          <div className="congrat-section_container-block">
            <div className="congrat-section_container-block_title">
              <h2>Congratulations!</h2>
            </div>
            <div className="congrat-section_container-block_subtitle">
              <p>
                You're on the waitlist and soon you'll be a real xfluencer,
                changing Twitter trends with others in special meme parties.
                We've also started cooking memecoins, talking to Elon, and
                prepping for the first meme party.
              </p>
            </div>
            <div className="congrat-section_container-block_subtitle-p">
              <p>
                Invite your friends using the link below. For each one who
                joins, you'll receive an additional airdrop of coins.
              </p>
            </div>
            <div className="congrat-section_container-block_h3">
              <h3>Your referral Link</h3>
            </div>

            <form>
              <div className="congrat-section_container-block_copy-field">
                <div className="congrat-section_container-block_copy-field_container">
                  <p>{referralLink}</p>
                </div>
              </div>
              <div className="congrat-section_container-block_button">
                <CustomYellowHoverBtn width="123px" height="57px" onClick={(e) => copyToClipboard(e)}>
                  <CopySVG />
                  Copy
                </CustomYellowHoverBtn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
