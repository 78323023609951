import React from "react";

import { useNavigate } from "react-router-dom";

import HomeIconFrog from "../../../../images/hero_imgs.png";

import "./HomeSection.scss";
import { CustomYellowHoverBtn } from "../../../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";

export const HomeSection: React.FC = () => {
  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };

  return (
    <section className="home-section" id="main">
      <div className="container">
        <div className="home-section_left">
          <h2>XFLUENCERs - it's you</h2>
          <p>
            Get meme coins for every like on your tweet and make Twitter more
            fun
          </p>
          <CustomYellowHoverBtn onClick={redirectToWhitelist}>
            Join Waitlist
          </CustomYellowHoverBtn>
        </div>
        <div className="home-section_right">
          <img src={HomeIconFrog} alt="Frog" />
        </div>
      </div>
    </section>
  );
};
