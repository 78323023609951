import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import "./AlredyJoined.scss";

export const AlredyJoined: React.FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this value based on when you want the counter to start
  });

  return (
    <section ref={ref} className="alredy-joined-section">
      <div className="container">
        <div className="alredy-joined-section_title">
          <h2>Already joined the waitlist:</h2>
        </div>
        <div className="alredy-joined-section_amount">
          {inView ? <CountUp end={2145} duration={2.5} /> : "0"}
          <span>Members</span>
        </div>
      </div>
    </section>
  );
};
