import React from "react";

import { useNavigate } from "react-router-dom";

import FooterFrogImg from "../../../../images/footer-frog.png";
// import FooterLogo from "../../../../images/header_logo_white.png";

import "./Footer.scss";
import { LogoFooterSVG } from "../../../../images/SVG/LogoFooter";

export const Footer: React.FC = () => {
  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="footer-section">
      <div className="container">
        <div className="footer-section-top">
          <div className="footer-section-top_logo">
            {/* <img src={FooterLogo} alt="logo" /> */}
            <LogoFooterSVG />
          </div>
          <div className="footer-section-top_nav">
            <ul className="block">
              <li onClick={() => handleScroll("main")}>Home</li>
              <li onClick={() => handleScroll("about")}>About</li>
              <li onClick={() => handleScroll("goals")}>Goals</li>
              <li onClick={() => handleScroll("faq")}>FAQ</li>
            </ul>

            <ul className="block">
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-conditions">Terms of Service</a></li>
              <li onClick={redirectToWhitelist}><a href="/">Join Waitlist</a></li>
            </ul>
          </div>
        </div>

        <div className="footer-section-bottom">
          <div className="footer-section-bottom_left">
            <div className="social">
              <p>Follow us: </p>
              <a href="https://t.me/xfluencersmeme">TG</a>
              <a href="https://x.com/xfluencersmeme">X</a>
            </div>
            <div className="undertext">
              <p>
                All rights reserved.
              </p>
              <p> We are cooking meme coins and buzzing with Elon.</p>
            </div>
          </div>

          <div className="footer-section-bottom_right">
            <div className="copyright">
              <p style={{ fontSize: 12 + "px" }}>XFLUENCERS © 2024</p>
            </div>
          </div>
        </div>

        <div className="footer-frog-img">
          <img src={FooterFrogImg} alt="frog" />
        </div>
      </div>
    </section>
  );
};
