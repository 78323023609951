import React from "react";
import "./TermsConditions.scss"; // Assuming you have some common styles

const TermsConditions: React.FC = () => {
  return (
    <div className="policy-terms-container">
      <h1>Terms and Conditions</h1>
      <p>Effective Date: 31.07.2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Xfluencers.wtf. By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions.
      </p>
      <h2>2. Registration</h2>
      <p>
        To use certain features, you must register by providing your email address and Twitter account. You agree to provide accurate and complete information and to keep it up-to-date.
      </p>
      <h2>3. Use of Services</h2>
      <p>You agree to use our website and services for lawful purposes only.</p>
      <p>You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account.</p>
      <h2>4. Intellectual Property</h2>
      <p>All content on our website, including text, graphics, and logos, is the property of Xfluencers.wtf or its licensors and is protected by intellectual property laws.</p>
      <h2>5. Limitation of Liability</h2>
      <p>We are not liable for any indirect, incidental, or consequential damages arising from your use of our website or services.</p>
      <h2>6. Changes to Terms</h2>
      <p>We may update these Terms and Conditions from time to time. The revised terms will be effective when posted on our website.</p>
      <h2>7. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to our services if you violate these Terms and Conditions.</p>
      <h2>8. Governing Law</h2>
      <p>These Terms and Conditions are governed by and construed in accordance with the laws of the United States of America.</p>
      <h2>9. Contact Us</h2>
      <p>For any questions or concerns regarding these Terms and Conditions, please contact us at hello@xfluencers.wtf.</p>
    </div>
  );
};

export default TermsConditions;
