import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import "./ConfirmationPage.scss";

export const ConfirmationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState<boolean | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const confirmationCode = queryParams.get('confirmationCode');

    const confirmEmail = async () => {
      if (confirmationCode) {
        try {
          const response = await axios.get(`https://xfluencersserver-production.up.railway.app/api/check-user/${confirmationCode}`);
          if (response.data.success && response.data.isConfirmed) {
            setIsConfirmed(true);

            const getTwitterLink = await axios.get(`https://xfluencersserver-production.up.railway.app/api/find-twitterName-user/${confirmationCode}`);
            
            const twitterUsername = getTwitterLink.data.twitterLink;
            const isEmailSent = getTwitterLink.data.emailSent;
            if (twitterUsername) {
              sessionStorage.setItem('twitterUsername', twitterUsername);
              console.log(isEmailSent);
              
              if (isEmailSent) {
                setTimeout(() => navigate("/congratulations-page"), 5000);
              }else{
                setTimeout(() => navigate("/tweet-page"), 5000);
              }
           
            }
          } else {
            setIsConfirmed(false);
          }
        } catch (error) {
          setIsConfirmed(false);
          console.error('Error confirming email:', error);
        }
      }
    };

    if (confirmationCode) {
      confirmEmail();
    }
  }, [location, navigate]);

  return (
    <section className="confirmation-section">
      <div className="container">
        {isConfirmed === null ? (
          <div className="confirmation-message">
            <h1>Confirming your email...</h1>
          </div>
        ) : isConfirmed ? (
          <div className="confirmation-message">
            <h1>Email Confirmed!</h1>
            <p>You will be redirected shortly...</p>
          </div>
        ) : (
          <div className="confirmation-message">
            <h1>Confirmation Failed</h1>
            <p>There was an issue confirming your email.</p>
          </div>
        )}
      </div>
    </section>
  );
};
