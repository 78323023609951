import React from "react";

import { useNavigate } from "react-router-dom";

import star1 from "../../../../images/stars/star_1.png";
import star2 from "../../../../images/stars/star_2.png";
import star3 from "../../../../images/stars/star_3.png";
import star4 from "../../../../images/stars/star_4.png";
import star5 from "../../../../images/stars/star_5.png";

import "./WhoWeAreSection.scss";
import { CustomYellowHoverBtn } from "../../../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";

export const WhoWeAreSection: React.FC = () => {
  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };

  return (
    <section className="who-we-are-section" id="about">
      <div className="container">
        <div className="text-content">
          <div className="stars">
            <img src={star1} alt="Star 1" className="star star1" />
            <img src={star2} alt="Star 2" className="star star2" />
            <img src={star3} alt="Star 3" className="star star3" />
            <img src={star4} alt="Star 4" className="star star4" />
            <img src={star5} alt="Star 5" className="star star5" />
          </div>
          <div className="text-content_title">
            <h2>Who we are...</h2>
            <h3>And what we are going to do?</h3>
          </div>
          <p>
            We're a meme community that loves Twitter and all things related to
            it (bad and good). With us, everyone becomes a fake influencer,
            receives monetizations on tweets he made, and does fun and
            meaningful things, like pushing dumb hashtags into trending or
            highlighting important issues.
          </p>
          <CustomYellowHoverBtn onClick={redirectToWhitelist}>
            Join Waitlist
          </CustomYellowHoverBtn>
        </div>
      </div>
    </section>
  );
};
