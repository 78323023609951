import React from "react";
import "./CustomPurpleHoverBtn.scss";

interface CustomPurpleHoverBtnProps {
  width?: string;
  height?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}

export const CustomPurpleHoverBtn: React.FC<CustomPurpleHoverBtnProps> = ({
  width = "200px",
  height = "41px",
  onClick,
  children,
}) => {
  return (
    <button
      className="custom-purple-hover-btn"
      style={{ width, height }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
