import React from "react";
import "../TermsConditions/TermsConditions.scss"; // Assuming you have some common styles

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="policy-terms-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: 31.07.2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Xfluencers.wtf. We are committed to protecting your privacy and ensuring a safe online experience. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website and services.
      </p>
      <h2>2. Information We Collect</h2>
      <p>
        <strong>Personal Information:</strong> When you register, you provide us with your email address and Twitter account.
      </p>
      <p>
        <strong>Usage Data:</strong> We collect data on how you interact with our website, including your IP address, browser type, and pages visited.
      </p>
      <p>
        <strong>Cookies and Tracking Technologies:</strong> We use cookies and other tracking technologies to enhance your experience and gather analytics.
      </p>
      <h2>3. How We Use Your Information</h2>
      <p>To manage and operate our website and services.</p>
      <p>To communicate with you about updates and promotions.</p>
      <p>To analyze usage patterns and improve our services.</p>
      <p>To send newsletters and other marketing materials, if you have opted in.</p>
      <h2>4. Data Sharing and Disclosure</h2>
      <p>We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our website and services, subject to confidentiality agreements.</p>
      <h2>5. Security</h2>
      <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure.</p>
      <h2>6. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information. You may also opt out of receiving marketing communications from us.</p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>
      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at hello@xfluencers.wtf.</p>
    </div>
  );
};

export default PrivacyPolicy;
