import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Navbar/Navbar.scss";
// import logo from "../../images/header_logo.png";
import { CustomYellowHoverBtn } from "../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";
import { LogoHeaderSVG } from "../../images/SVG/LogoHeader";

export const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };


  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <nav className="navbar">
      <div className="container">
        <div className="left-conatiner">
          <ul>
            <Link to={"/"} onClick={() => handleScroll("main")}>
              <li>Home</li>
            </Link>
            <li onClick={() => handleScroll("about")}>About us</li>
            <li onClick={() => handleScroll("goals")}>Goals</li>
            <li onClick={() => handleScroll("faq")}>FAQ</li>
          </ul>
          <Link to={"/"}>
            <LogoHeaderSVG />
          </Link>
        </div>
        <CustomYellowHoverBtn width="146px" onClick={redirectToWhitelist}>
          Join Waitlist
        </CustomYellowHoverBtn>
      </div>
    </nav>
  );
};
