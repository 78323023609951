import React, { useState, useEffect } from "react";

import AvatarIcon from "../../images/avatar.png";

// import { toast } from "react-toastify";

import { ImageBoxSVG } from "../../images/SVG/ImageBox";
import { FilterSVG } from "../../images/SVG/Filter";
import { SmileSVG } from "../../images/SVG/Smile";
import { EarthSVG } from "../../images/SVG/Earth";
import { CalendarSVG } from "../../images/SVG/Calendar";

import "./TweetPage.scss";



const tweetMessages = [
  "They @XFLUENCERSWTF wants to pay me for every like I will receive on this tweet.\n\n#memecoins #crypto",
  "Good News. I’ve just joined the @Xfluencerswtf waitlist and am ready to move memes into trends.\n#crypto #cryptocommunity",
  "They @XFLUENCERSWTF will pay me for every like I will receive on this tweet.\n#memecoin #crypto",
  "Like this tweet because I will receive money from @xfluencerswtf for every like I will receive on this tweet.\n#crypto",
  "GM! I'VE JOINED @XFLUENCERSWTF club and earned my first $xmemecoin.\n#memecoins",
  "They @Xfluencerswtf let me in, I wanna receive memecoins for every like I receive.\n#memecoins",
  "Attention please! I'VE JOINED @XFLUENCERSWTF and earned my first $xmemecoin.\n#memecoins"
];


export function TweetPage() {
  const [tweetMessage, setTweetMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * tweetMessages.length);
    setTweetMessage(tweetMessages[randomIndex]);
  }, []);

  const checkTweet = async (twitterUsername: string, tweetMessage: string) => {
    setIsLoading(true); // Start loading
    // try {
    //   // setIsLoading(true); // Start loading
    //   const response = await fetch(`https://xfluencersserver-production.up.railway.app/api/check-tweet/${twitterUsername}`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ tweetMessage })
    //   });
    //   const data = await response.json();
    //   if (data.success) {
    //     window.location.href = '/congratulations-page';
    //   } else {
    //     toast.error('Tweet not found. Please tweet the correct message.');
    //   }
    // } catch (error: any) {
    //   toast.error('Failed to check tweet:', error.message);
    // }
     window.location.href = '/congratulations-page';
    setIsLoading(false); // End loading
  };


  const handleTweet = () => {
    const tweetMessage2 = encodeURIComponent(tweetMessage);
    const twitterUrl = `http://twitter.com/intent/tweet?text=${tweetMessage2}`;
    const newWindow = window.open(twitterUrl, 'twitterwindow', 'left=20,top=20,width=600,height=300,toolbar=0,resizable=1');

    if (newWindow) {
      // If the window was successfully opened
      const checkWindowClosed = () => {
        if (newWindow.closed) {
          console.log('Twitter window has been closed');
          clearInterval(checkInterval); // Stop the interval when the window is closed
        }
      };

      // Set an interval to check if the window is closed
      let checkInterval = setInterval(checkWindowClosed, 1000);
    } else {
      console.log('Twitter window has been OPENED');
      // If the window could not be opened
      console.error('Failed to open the Twitter window. This may be due to a popup blocker.');
    }
  };




  return (
    <div className="tweet-section">
      <div className="container">
        <div className="tweet-section_container">
          <div className="tweet-section_container-block">
            <div className="tweet-section_container-block_title">
              <h2>Finish your application</h2>
            </div>
            <div className="tweet-section_container-block_subtitle">
              <p>
                We're opening registration for the first xfluencers who will
                shape Twitter trends and have fun collecting likes and
                memecoins.
              </p>
            </div>
            <div className="tweet-section_container-block_subtitle-h3">
              <h3>
                YOU ARE JUST ONE CLICK AWAY FROM OUR WAITLIST. SHARE YOUR FIRST
                TWEET AS AN XFLUENCER
              </h3>
            </div>

            <div className="tweet-section_container-block_tweet-item">
              <div className="tweet-section_container-block_tweet-item__top">
                <span>Drafts</span>
              </div>
              <div className="tweet-section_container-block_tweet-item__content">
                <img src={AvatarIcon} alt="avatar" />
                <p>{tweetMessage}</p>
              </div>
              <div className="tweet-section_container-block_tweet-item__reply">
                {/* <img src={GlobIcon} alt="avatar" /> */}
                <EarthSVG />
                <p>Everyone can reply</p>
              </div>
              <div className="tweet-section_container-block_tweet-item__bottom_bar">
                <div className="left-btns">
                  {/* <img src={CalendarIcon} alt="calendar" /> */}
                  <CalendarSVG />
                  {/* <img src={ImgBoxIcon} alt="calendar" /> */}
                  <ImageBoxSVG />
                  {/* <img src={FilterIcon} alt="calendar" /> */}
                  <FilterSVG />
                  {/* <img src={HappyIcon} alt="calendar" /> */}
                  <SmileSVG />
                </div>
                <div className="action-btn">
                  <button onClick={handleTweet}>Tweet It!</button>
                  <button 
                  className="check-btn"
                  onClick={() => {
                    const twitterUser = sessionStorage.getItem('twitterUsername'); // Retrieve the Twitter user ID from localStorage
                    if (twitterUser) {
                      checkTweet(twitterUser, tweetMessage);
                    } else {
                      console.error('No Twitter user ID found in localStorage.');
                    }
                  }} disabled={false}>
                    {isLoading ? 'Checking...' : 'Check'}
                  </button>
                </div>
              </div>
            </div>

            <div className="tweet-section_container-block_alert">
              <span>
                *you should share it to the twitter you mentioned earlier
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
