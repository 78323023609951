import React from "react";
import { useNavigate } from "react-router-dom";

import GoalImg from "../../../../images/goal_imgs.png";
// import CheckImg from "../../../../images/check_img.png";
import { CheckBoxSVG } from "../../../../images/SVG/Check";

import "./OurGoals.scss";
import { CustomYellowHoverBtn } from "../../../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";


const goals = [
  "10,000 Xfluencers",
  "1,000,000 tweets published",
  "1,000,000 likes received",
  "10 trending hashtag spots captured",
  "Elon joined",
  "1,000,000 memecoins paid out",
  "Our own Ecosystem of projects launched",
  "Listing on Major CEXes",
];

export const OurGoals: React.FC = () => {
  const navigate = useNavigate();


  const redirectToWhitelist = () => {
    navigate("/form-page");
  };


  return (
    <section className="our-goals" id="goals">
      <div className="container">
        <div className="our-goals__content">
          <div className="our-goals__content-img">
            <img src={GoalImg} alt="goal" />
          </div>
          <div className="our-goals__content-text">
            <div className="our-goals__content-text-title">
              <h3>OUR GOALS</h3>
            </div>
            <div className="our-goals__content-text-list">
              {goals.map((goal, index) => (
                <div key={index} className="our-goals__content-text-list-item">
                  <CheckBoxSVG />
                  <p>{goal}</p>
                </div>
              ))}
            </div>
            <div className="our-goals__content-text-button">
              <CustomYellowHoverBtn onClick={redirectToWhitelist}>Join Our Meme Squad</CustomYellowHoverBtn>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
