import React from "react";
import "./CustomYellowHoverBtn.scss";

interface CustomYellowHoverBtnProps {
  width?: string;
  height?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}

export const CustomYellowHoverBtn: React.FC<CustomYellowHoverBtnProps> = ({
  width = "200px",
  height = "41px",
  onClick,
  children,
  isDisabled = false
}) => {
  return (
    <button
      className="custom-yellow-hover-btn"
      style={{ width, height }}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
