import React from "react";
import { HomeSection } from "./components/HomeSection/HomeSection";
import { WhoWeAreSection } from "./components/WhoWeAreSection/WhoWeAreSection";
import { HowItWork } from "./components/HowItWork/HowItWork";
import { AlredyJoined } from "./components/AlredyJoined/AlredyJoined";
import { OurGoals } from "./components/OurGoals/OurGoals";
import { Questions } from "./components/Questions/Questions";
import { Newsletter } from "./components/Newsletter/Newsletter";
import { Footer } from "./components/Footer/Footer";

import "./MainContent.scss";

export const MainContent: React.FC = () => {
  return (
    <main className="main-content">
      <HomeSection />
      <WhoWeAreSection />
      <HowItWork />
      <AlredyJoined />
      <OurGoals />
      <Questions />
      <Newsletter />
      <Footer />
    </main>
  );
};
