import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar";
import { MainContent } from "./components/MainContent/MainContent";
import { FormPage } from "./components/FormPage/FormPage";
import { Footer } from "./components/MainContent/components/Footer/Footer";
import { TweetPage } from "./components/TweetPage/TweetPage";
import { CongratPage } from "./components/CongratPage/CongratPage";
import { ConfirmationPage } from "./components/MainContent/components/ConfirmationPage/ConfiramtionPage";

import PrivacyPolicy from "./components/MainContent/components/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./components/MainContent/components/TermsConditions/TermsConditions";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";




function Layout() {
  const location = useLocation();

  useEffect(() => {
    // Перевіряємо, чи користувач знаходиться на головній сторінці
    if (location.pathname === '/') {
      const searchParams = new URLSearchParams(location.search);
      const referralCode = searchParams.get('r');
      if (referralCode) {
        // Якщо реферальний код присутній у URL, зберігаємо його в sessionStorage
        sessionStorage.setItem('referralCode', referralCode);
      } else {
        // Якщо реферальний код відсутній, видаляємо з sessionStorage
        sessionStorage.removeItem('referralCode');
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/form-page" element={<FormPage />} />
        <Route path="/tweet-page" element={<TweetPage />} />
        <Route path="/congratulations-page" element={<CongratPage />} />

        <Route path="/confirmation-page" element={<ConfirmationPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
      {["/form-page", "/tweet-page", "/congratulations-page", "/confirmation-page"].includes(
        location.pathname,
      ) && <Footer />}
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Layout />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
