import React from "react";

import { useNavigate } from "react-router-dom";

import Arrow1Icon from "../../../../images/Vector_arrow_1.png";
import Arrow2Icon from "../../../../images/Vector_arrow_2.png";
import Arrow3Icon from "../../../../images/Vector_arrow_3.png";

import FoxImg from "../../../../images/steps/fox.png";
import TweetImg from "../../../../images/steps/tweet.png";
import MemeImg from "../../../../images/steps/meme.png";
import CoinImg from "../../../../images/steps/coin.png";


import Arrow4Icon from "../../../../images/Vector_arrow_4.png";
import Arrow5Icon from "../../../../images/Vector_arrow_5.png";
import Arrow6Icon from "../../../../images/Vector_arrow_6.png";

import { FoxSVG } from "../../../../images/SVG/Fox";
import { TweetSVG } from "../../../../images/SVG/Tweet";
import { MemeSVG } from "../../../../images/SVG/Meme";
import { CoinSVG } from "../../../../images/SVG/Coin";

import "./HowItWork.scss";
import { CustomYellowHoverBtn } from "../../../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";


export const HowItWork: React.FC = () => {
  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };

  return (
    <section className="how-it-work">
      <div className="container">
        <div className="how-it-work_title">
          <h2>How does it work?</h2>
        </div>

        <div className="how-it-work_arrows-1440">
          <div className="arrow1">
            <div className="step1">
              <FoxSVG />
              <h5>Log in with your wallet</h5>
            </div>
            <img src={Arrow1Icon} alt="Arrow1Icon" />
            <div className="step2">
              <TweetSVG />
              <h5>confirm Twitter</h5>
            </div>
          </div>
          <div className="arrow2">
            <div className="step3">
              <MemeSVG />
              <h5>join the 'meme party' and start tweeting from the site</h5>
            </div>
            <img src={Arrow2Icon} alt="Arrow2Icon" />
          </div>
          <div className="arrow3">
            <img src={Arrow3Icon} alt="Arrow3Icon" />
            <div className="step4">
              <CoinSVG />
              <h5>get meme coins for every like received</h5>
            </div>
          </div>
        </div>

        
        <div className="how-it-work_arrows-960">
          <div className="item1">
            <img src={FoxImg} alt="" />
            <h5>Log in with your wallet</h5>
          </div>
          <div className="arrow">
            <img src={Arrow4Icon} alt="arrow4" />
          </div>
          <div className="item2">
            <img src={TweetImg} alt="" />
            <h5>confirm Twitter</h5>
          </div>
          <div className="arrow">
            <img src={Arrow5Icon} alt="arrow5" />
          </div>
          <div className="item3">
            <img src={MemeImg} alt="" />
            <h5>join the 'meme party' and start tweeting from the site</h5>
          </div>
          <div className="arrow">
            <img src={Arrow6Icon} alt="arrow6" />
          </div>
          <div className="item4">
            <img src={CoinImg} alt="" />
            <h5>get meme coins for every like received</h5>
          </div>
        </div>


        <div className="join-btn">
          <CustomYellowHoverBtn onClick={redirectToWhitelist}>
            Join Waitlist
          </CustomYellowHoverBtn>
        </div>

      </div>
    </section>
  );
};
