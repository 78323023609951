import React, { useState } from "react";
import "./Questions.scss";

export const Questions: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const questions = [
    {
      question: "Who are Xfluencers?",
      answer:
        "We are a community of users who love Twitter, memecoins, NFTs, and cryptocurrencies. We come together to have fun and do big&fun things on Twitter.",
    },
    {
      question: "What is a meme party?",
      answer:
        "It's a campaign to get a specific hashtag or topic trending on Twitter by posting pre-planned tweets. Our goal is to force twitter users to see our tweets everywhere.",
    },
    {
      question: "What is the goal of Xfluencers?",
      answer:
        "We aim to give everyone what Twitter doesn't – monetization for all users and the chance to trend, feel like an influencer, and be part of the meme community.",
    },
    {
      question: "Why are meme coins paid for likes?",
      answer:
        "Because everyone loves likes and everyone gets them, even by accident. Likes matter, finally!",
    },
    {
      question: "How do you fight against manipulation with likes?",
      answer:
        "We randomly check our Xfluencers for manipulations with likes and take action when needed.",
    },
  ];

  return (
    <section className="questions-section" id="faq">
      <div className="container">
        <div className="questions__container-content">
          <div className="questions__container-content-title">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="questions__container-content-list">
            {questions.map((item, index) => (
              <div
                key={index}
                className="questions__container-content-list-item"
              >
                <div className="question" onClick={() => toggleAnswer(index)}>
                  <span>{item.question}</span>
                  <button className="toggle-button">
                    {openIndex === index ? "−" : "+"}
                  </button>
                </div>
                <div
                  className={`answer ${
                    openIndex === index ? "answer--open" : ""
                  }`}
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
