import React from "react";
import { useNavigate } from "react-router-dom";

import { CustomPurpleHoverBtn } from "../../../Buttons/CustomPurpleHoverBtn/CustomPurpleHoverBtn";

import "./Newsletter.scss";

export const Newsletter: React.FC = () => {

  const navigate = useNavigate();

  const redirectToWhitelist = () => {
    navigate("/form-page");
  };


  return (
    <section className="newsletter-section">
      <div className="container">
        <div className="newsletter__content">
          <div className="newsletter__content__container">
            <div className="newsletter__content__container-title">
              <h3>Subscribe to our newsletter</h3>
            </div>
            <div className="newsletter__content__container-undertitle">
              <p>
                Subscribe to our news which we kindly deliver by email. No spam, just news and unexpected airdrops.
              </p>
            </div>
            <div className="newsletter__content__container-button">
              <CustomPurpleHoverBtn onClick={redirectToWhitelist}>Subscribe</CustomPurpleHoverBtn>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
